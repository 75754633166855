@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,500;1,300&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a:link, a:visited {
  text-decoration: none;
  color: inherit;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    border: 1px solid transparent;
  }
  html, body {
    height: 95%;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }

  @page {
    margin: 0;
  }

  ::-webkit-input-placeholder {
    color: transparent !important;
  }
  ::-moz-placeholder {
    color: transparent !important;
  }
  :-ms-input-placeholder {
    color: transparent !important;
  }
  :-moz-placeholder {
    color: transparent !important;
  }

  fieldset {
    border: none transparent !important;
  }

  .print-report-container {
    padding: 0 !important;
    margin: 0 !important;
    background: #fff !important;
  }

  .print-document-page {
    padding: 0 !important;
    margin: 0 !important;
    background: #fff !important;
  }

  .print-document-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  .tox {
    padding: 0 !important;
    margin: 0 !important;
    background: #fff !important;
    border: none !important;
    page-break-after: avoid !important;
  }

  .ql-toolbar {
    display: none !important;
  }

  .MuiTooltip-tooltip {
    display: none !important;
  }

  .MuiSpeedDial-root {
    display: none !important;
  }

  .MuiIconButton-root {
    display: none !important;
  }

  .MuiCollapse-root .MuiInputLabel-root {
      display: none !important;
  }

  .MuiFormControl-root .MuiSvgIcon-root {
    display: none !important;
  }

  .MuiGrid-root {
    page-break-inside: avoid !important;
  }
}

